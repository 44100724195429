import { genHrs, reqHrs } from '../../utils/constant'

export const woByJobGetFilterOptions = [
  {
    name: genHrs,
    type: 'range',
  },
  {
    name: reqHrs,
    type: 'range',
  },
  {
    name: 'assigneesCount',
    type: 'range',
  },
  {
    name: 'unassignedHours',
    type: 'range',
  },
  {
    name: 'totalHours',
    type: 'range',
  },
  {
    name: 'assignedHours',
    type: 'range',
  },
  {
    name: 'startDate',
    type: 'dateRange',
  },
  {
    name: 'endDate',
    type: 'dateRange',
  },
  {
    name: 'city',
    type: 'multiselect',
  },
  {
    name: 'assignees',
    type: 'multiselect',
  },
  {
    name: 'clientId',
    type: 'multiselect',
  },
  {
    name: 'clientName',
    type: 'multiselect',
  },
  {
    name: 'jobManager',
    type: 'multiselect',
  },
  {
    name: 'operatingUnit',
    type: 'multiselect',
  },
  {
    name: 'name',
    type: 'multiselect',
  },
  {
    name: 'originalId',
    type: 'multiselect',
  },
  {
    name: 'postalCode',
    type: 'multiselect',
  },
  {
    name: 'isForeignJob',
    type: 'dropdown',
  },
  {
    name: 'lastUpdatedAt',
    type: 'dateRange',
  },
  {
    name: 'lastUpdatedBy',
    type: 'multiselect',
  },
  {
    name: 'jobPartner',
    type: 'multiselect',
  },
  {
    name: 'fee',
    type: 'range',
  },
  {
    name: 'expectedEarnings',
    type: 'range',
  },
  {
    name: 'expectedExpenses',
    type: 'range',
  },
  {
    name: 'jobPartnerId',
    type: 'multiselect',
  },

  // Only for visual purpose
  {
    name: 'jobLabels',
    type: 'autoComplete',
  },

  {
    name: 'jobManagerId',
    type: 'multiselect',
  },
  {
    name: 'financialYear',
    type: 'multiselect',
  },
]

export const woByJobDefaultFilterOptions = ['isForeignJob']

export const rangeTypes = ['range', 'dateRange']
